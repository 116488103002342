//Imports
import { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { changeCourseStatus } from "../../services/courses";

function CourseStatus({ title, id, status, author }){

    const { setOpen } = useContext(ModalContext)

    const handleConfirmed = async () => {
        const response = await changeCourseStatus(id, status, author);
        if( response ){
            ( response.status === 200 ) ? window.location.reload() : console.log('Algo ha salido mal...');
        }
    }

    return(
        <div className="createStatusPadre">
            <h3>¿ Seguro que quieres { title } el curso ?</h3>
            <div className="content">
                <p className="cancel" onClick={() => setOpen(false)}>Cancelar</p>
                <p className="continue" onClick={() => handleConfirmed()}>{ title }</p>
            </div>
        </div>
    )
}
export default CourseStatus;