


function ErrorPage(){
    
    window.location.assign('/')
    
    return(
        <></>
    )
}
export default ErrorPage;