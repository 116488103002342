//Imports
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BACK_URL;

export async function getAllCoursesDB() {
    try {
        const response = await axios({
            url: `${baseUrl}/api/courses`,
            method: 'GET'
        });
        return response;
    } catch (error) {
        return error.response;
    }
}

export async function getAllCoursesFromCanvasDB(university) {
    try {
        const response = await axios({
            url: `${baseUrl}/api/courses/canvas/${university}`,
            method: 'GET'
        });
        return response;
    } catch (error) {
        return error.response;
    }
}

export async function createNewCourses(data) {
    try {
        const response = await axios({
            url: `${baseUrl}/api/courses`,
            method: 'POST',
            data
        });
        return response;
    } catch (error) {
        return error.response;
    }
}

export async function changeCourseStatus(id, status, author) {
    try {
        const response = await axios({
            url: `${baseUrl}/api/course/status/${id}/${status}/${author}`,
            method: 'GET'
        });
        return response;
    } catch (error) {
        return error.response;
    }
}