//Imports
import { createContext, useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest, graphConfig } from '../middleware/msal-config';
import axios from "axios";

export const InfoUserContext = createContext();
export const InfoUserProvider = ({ children }) => {

    //Guardar info del usuario
    const [mailUser, setMailUser] = useState('');

    //Call to msal, obtener token e intercambiarlo por la infor del user
    const { instance, accounts, inProgress } = useMsal();
	const saveUserData = () => {
		const request = {
			...loginRequest,
			account: accounts[0]
		}
	
		instance.acquireTokenSilent(request).then( async response => {
			try {
                // console.log( response.account.username )
                // console.log( response.idTokenClaims.roles[0] )
                // console.log( (response.idToken.claims.roles) );
				setMailUser( response.account.username.split('@')[0] );
				
			} catch (error) {
				console.log( error )
			}
		}).catch((e) => {
			instance.acquireTokenRedirect(request).then((response) => {
				console.log('Ha habido un error al traer el token', response)
			})
		})
	}

    
    //Verificar si el usuario esta autentificado
    const isAuthentificated = useIsAuthenticated();
    useEffect(() => {
        if( isAuthentificated ){
            saveUserData();
        }
    }, [isAuthentificated])

    return(
        <InfoUserContext.Provider value={{ mailUser }}>
            { children }
        </InfoUserContext.Provider>
    )
}

