//Imports
import { useEffect, useState } from "react";
import { createNewCourses, getAllCoursesFromCanvasDB } from "../../services/courses";


function CreateCourses({ university, author, coursesDB }){

    const [coursesToEnable, setCoursesToEnable] = useState([]);
    const [filter, setFilter] = useState({ active: false, value:'' });
    const [courses, setCourses] = useState([]);
    
    useEffect(() => {
        async function loadData(){
            const response = await getAllCoursesFromCanvasDB(university);
            if( response ){
                setCourses(response.data.courses)
            }
        }
        loadData();
    }, [])
    
    const handleSubmit = async e => {
        e.preventDefault();

        if( coursesToEnable.length > 0 ){
            const response = await createNewCourses({ university, author, list_courses: coursesToEnable });
            if( response ){
                window.location.reload();
            }
        }
    }

    const handleCourses = async object => {
        // Manejar aquí el array de los cursos a Insertar
        //      - No seleccionar a los que ya están en la DB 
        //      - No se puede seleccionar repetidos
        if( !coursesToEnable.some(item => item.sis_id.trim() === object.sis_id.trim()) && !coursesDB?.some(item => ( item.sis_id.trim() === object.sis_id.trim() ) && ( item.university.trim() === university.trim() )) ){ //Si no está metido todavía
            setCoursesToEnable([...coursesToEnable, object])
        }else{
            console.log('Ese curso ya está seleccionado o activado');
        }
    }
    
    const handleCoursesAll = async () => {        
        
        let arrayTemporal = [];
        const filteredCoursesByFilters = courses.filter(e => e.name.toLowerCase().includes(filter.value.toLowerCase()) || e.course_id?.toString().includes(filter.value.trim()) || e.sis_id.toLowerCase().includes(filter.value.toLowerCase()) )

        filteredCoursesByFilters.map(( object ) => {
            if( !coursesToEnable.some(item => item.sis_id.trim() === object.sis_id.trim()) && !coursesDB?.some(item => ( item.sis_id.trim() === object.sis_id.trim() ) && ( item.university.trim() === university.trim() )) ){ //Si no está metido todavía
                arrayTemporal.push( object );
            }else{
                console.log('Ese curso ya está seleccionado o activado');
            }
        })
        setCoursesToEnable(arrayTemporal);
    }
    
    const handleFilters = value => {
        if( value.length > 1 ){
            setFilter({ active: true, value }) 
        }else{
            setFilter({ active: false, value })
        }
    }

    return(
        <form onSubmit={handleSubmit} className='createForm'>
            <div className='center'>
                <input type="text" placeholder='SIS Id, Nombre del Curso o Id' value={filter.value} onChange={e => handleFilters(e.target.value)} />
                <button onClick={() => handleCoursesAll()} type="button">Select All</button>
            </div>
            <div className='list'>
                {
                    courses.map(({ name, sis_id, course_id }, i) => (
                        ( ( filter.active ) && ( name.toLowerCase().includes(filter.value.toLowerCase()) || course_id?.toString().includes(filter.value.trim()) || sis_id.toLowerCase().includes(filter.value.toLowerCase()) ) ) &&
                        <div key={i} onClick={() => handleCourses({ name, sis_id, course_id })}>
                            <p>{name}</p>
                            <p>{sis_id}</p>
                        </div>
                    ))
                }
            </div>
            <div className='listSelected'>
                {   coursesToEnable.length > 0 && <h3>Lista de Cursos Para Añadir:</h3> }
                <div className="courses">
                    {   coursesToEnable.map(({ sis_id }, i) => (
                            <div key={i}>
                                <p>{sis_id}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
            <input type="submit" value="Enviar" />
        </form>
    )
}
export default CreateCourses;