export const msalConfig = {
    auth: {
        clientId: 'a2506c13-eaf9-4f44-92f2-be2e5622d4e5',
        authority: 'https://login.microsoftonline.com/ce6c57e7-70a3-449f-b0b9-300681e00150',
        redirectUri: process.env.REACT_APP_WEB_URL
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
}
export const loginRequest = {
    scopes: ['User.Read']
}
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
}