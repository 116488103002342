//Imports
import { useState } from "react";
import { createNewUsers, getUserNameFromCanvas } from "../../services/users";


function Create({ university, author }){

    const [usersToAdd, setUsersToAdd] = useState([]);
    const [userId, setUserId] = useState('');

    const handleUsers = async () => {
        if( !usersToAdd.some(e => parseInt(e.id) === parseInt(userId) ) ){ //Si no está metido todavía
            const response = await getUserNameFromCanvas(userId, university);
            if( response ){
                if( response.status === 200 ){
                    setUsersToAdd([...usersToAdd, { name: response.data.name, id: parseInt(userId.trim()) } ]) //cuando le quiera añadir a la lista
                }else{
                    console.log('user no encontrado');
                }
            }
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();

        if( usersToAdd.length > 0 ){
            const response = await createNewUsers({ university, list_users: usersToAdd, author});
            if( response ){
                console.log(response.status);
                console.log(response.data);
                window.location.reload();
            }
        }
    }


    return(
        <form onSubmit={handleSubmit} className='createForm'>
            <div className='center'>
                <input type="number" placeholder='Id Usuario' value={userId} onChange={e => setUserId(e.target.value)} />
                <input type='button' onClick={() => handleUsers()} value="Añadir" />
            </div>
            <div className='list'>
                {   ( usersToAdd.length > 0 ) &&
                    usersToAdd.map(({ name, id }, i) => (
                        <div key={i}>
                            <p>{name}</p>
                            <p>{id}</p>
                        </div>
                    ))
                }
            </div>
            <input type="submit" value="Enviar" />
        </form>
    )
}
export default Create;