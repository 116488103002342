//Imports
import { useContext, useEffect, useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { getAllUsersDB } from '../services/users';
import getCorrectDate from '../middleware/convert-date'

import Create from '../utils/create/CreateUsers';
import ModalButton from '../utils/ModalButton';
import LogoutButton from './LogoutButton';
import BackButton from '../utils/BackButton';
import { InfoUserContext } from '../context/InfoUser';
import { ModalContext } from "../context/ModalContext";
import UserStatus from "../utils/status/UserStatus";
import { Link, useParams } from 'react-router-dom';


function Users(){

    // Validate Route
    const isAuthenticated = useIsAuthenticated();
    if( !isAuthenticated ){
        window.location.assign("/");
    }

    // Get Author by msal
    const { mailUser } = useContext(InfoUserContext);
    const { setOpen, setContent, setType } = useContext(ModalContext);

    const { uni } = useParams();

    const [universityConfig, setUniversityConfig] = useState({ selected: uni, options: [{name: 'PROD MIAMI', code: 'prod_miami'}, {name: 'PROD MIT DPP', code: 'prod_mitpedpp'}, {name: 'PROD UCPE', code: 'prod_ucpe'}, {name: 'PROD BOOTH', code: 'prod_booth'}, {name: 'PROD ITAM', code: 'prod_itam'}, {name: 'PROD XPRO', code: 'prod_mitxpro'}, {name: 'PROD UPENN', code: 'prod_upenn'}] });
    const [showLogoutCounter, setShowLogoutCounter] = useState(0);
    const [users, setUsers] = useState([]);
    const [filterUser, setFilterUser] = useState({ active: false, value: '' });


    useEffect(() => {
        async function loadData(){
            const response = await getAllUsersDB();
            if( response ){
                setUsers(response.data.users)
            }
        }
        loadData();
    }, [])

    const handleFilters = value => {
        if( value.length > 0 ){
            setFilterUser({ active: true, value })
        }else{
            setFilterUser({ active: false, value })
        }
    }

    const handleStatusUser = (id, status) => {
        const title = ( status ) ? 'desactivar' : 'activar';

        setContent({ title: `${title} Curso`, body: <UserStatus title={title} id={id} status={status} author={mailUser} /> });
        setType('status');
        setOpen(true);
    }

    return(
        <div className='layoutPadre'>
            <BackButton backUrl={`/courses/${uni}`} title='Courses' />
            { showLogoutCounter >= 3 && <LogoutButton/> }
            <div className="title" onClick={() => setShowLogoutCounter(showLogoutCounter + 1)}>
                <h1>Usuarios Registrados por Universidad</h1>
            </div>
            <div className='universities'>
                {
                    universityConfig.options.map(({ name, code }) => (
                        <Link to={`/users/${code}`} key={code} className={code === universityConfig.selected ? 'active' : ''} onClick={ () => setUniversityConfig({...universityConfig, selected: code})}>{ name }</Link>
                    ))
                }
            </div>
            { ( universityConfig.selected ) &&
                <div className='content'>
                    <div className='filters'>
                        <input value={filterUser.value} onChange={e => handleFilters(e.target.value)} placeholder='Filtrar por Nombre, Email o Id del Usuario' />
                        <ModalButton props={{ title: 'Create User', body: <Create university={universityConfig.selected} author={mailUser} />}} type='User' />
                    </div>
                    <div className='list user'>
                        <div className='header'>
                            <p>Status</p>
                            <p>Email</p>
                            <p>Course</p>
                            <p>Name User</p>
                            <p>User ID</p>
                            <p>Author</p>
                            <p>Updated At</p>
                        </div>
                        {
                            users?.map(({ id, email, id_course, name_user, id_user, university, author, active, updated_at }, i) => (
                                ( filterUser.active ? ( email?.toLowerCase().includes(filterUser.value.toLowerCase().trim()) || id_user?.toString().includes(filterUser.value.trim()) || name_user?.toLowerCase().includes(filterUser.value.toLowerCase().trim()) ) : true ) &&
                                ( university === universityConfig.selected ) &&
                                <div key={i} className='item'>
                                    <p className={ active ? 'status activo' : 'status inactivo' } onClick={() => handleStatusUser(id, active)}>{ active ? 'activo' : 'inactivo' }</p>
                                    <p>{ email }</p>
                                    <p>{ id_course }</p>
                                    <p>{ name_user }</p>
                                    <p>{ id_user }</p>
                                    <p>{ author }</p>
                                    <p>{ getCorrectDate(updated_at) }</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            }
        </div>
    )
}
export default Users;