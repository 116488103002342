//Imports
import { useContext, useEffect, useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";

import BackButton from "../utils/BackButton";
import ModalButton from "../utils/ModalButton";
import CreateCourses from "../utils/create/CreateCourses";
import { getAllCoursesDB } from "../services/courses";
import getCorrectDate from "../middleware/convert-date";
import { InfoUserContext } from "../context/InfoUser";
import { ModalContext } from "../context/ModalContext";
import CourseStatus from "../utils/status/CourseStatus";
import { Link, useParams } from "react-router-dom";

function Courses(){

    // Validate Route
    const isAuthenticated = useIsAuthenticated();
    if( !isAuthenticated ){
        window.location.assign("/");
    }

    // Get Author by msal
    const { mailUser } = useContext(InfoUserContext);
    const { setOpen, setContent, setType } = useContext(ModalContext);

    const { uni } = useParams();
    
    const [universityConfig, setUniversityConfig] = useState({ selected: uni, options: [{name: 'PROD MIAMI', code: 'prod_miami'}, {name: 'PROD MIT DPP', code: 'prod_mitpedpp'}, {name: 'PROD UCPE', code: 'prod_ucpe'}, {name: 'PROD BOOTH', code: 'prod_booth'}, {name: 'PROD ITAM', code: 'prod_itam'}, {name: 'PROD XPRO', code: 'prod_mitxpro'}, {name: 'PROD UPENN', code: 'prod_upenn'}] });
    const [courses, setCourses] = useState([]);
    const [filterUser, setFilterUser] = useState({ active: false, value: '' });

    useEffect(() => {
        async function loadData(){
            const response = await getAllCoursesDB();
            if( response ){
                setCourses(response.data.courses)
            }
        }
        loadData();
    }, [])

    const handleFilters = value => {
        if( value.length > 0 ){
            setFilterUser({ active: true, value });
        }else{
            setFilterUser({ active: false, value });
        }
    }

    const handleStatusCourse = (id, status) => {
        const title = ( status ) ? 'desactivar' : 'activar';

        setContent({ title: `${title} Curso`, body: <CourseStatus title={title} id={id} status={status} author={mailUser} /> });
        setType('status');
        setOpen(true);
    }

    return(
        <div className="layoutPadre">
            <BackButton backUrl={`/users/${uni}`}  title='Users' />
            <div className="title">
                <h1>Cursos Habilitados por Universidad</h1>
            </div>
            <div className="universities">
                {   
                    universityConfig.options.map(({ name, code }) => (
                        <Link to={`/courses/${code}`} key={code} className={code === universityConfig.selected ? 'active' : ''} onClick={ () => setUniversityConfig({...universityConfig, selected: code})}>{ name }</Link>
                    ))
                }   
            </div>
            { ( universityConfig.selected ) &&
                <div className='content'>
                    <div className='filters'>
                        <input value={filterUser.value} onChange={e => handleFilters(e.target.value)} placeholder='Filtrar por SIS Id, Nombre del Curso o Id' />
                        <ModalButton props={{ title: 'Create Course', body: <CreateCourses university={universityConfig.selected} author={mailUser} coursesDB={courses} />}} type='Course' />
                    </div>
                    <div className='list'>
                        <div className='header'>
                            <p>Status</p>
                            <p>Course Name</p>
                            <p>ID</p>
                            <p>SIS ID</p>
                            <p>Author</p>
                            <p>Updated At</p>
                        </div>
                        {
                            courses?.map(({ id, course_id, sis_id, name, university, active, author, updated_at }, i) => (
                                ( filterUser.active ? ( name?.toLowerCase().includes(filterUser.value.toLowerCase().trim()) || course_id?.toString().includes(filterUser.value.trim()) || sis_id?.toString().toLowerCase().includes(filterUser.value.toString().toLowerCase().trim()) ) : true ) &&
                                ( university === universityConfig.selected ) &&
                                <div key={i} className='item'>
                                    <p className={ active ? 'status activo' : 'status inactivo' } onClick={() => handleStatusCourse(id, active)}>{ active ? 'activo' : 'inactivo' }</p>
                                    <p>{ name }</p>
                                    <p>{ course_id }</p>
                                    <p>{ sis_id }</p>
                                    <p>{ author }</p>
                                    <p>{ getCorrectDate(updated_at) }</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            }
        </div>
    )
}
export default Courses;