export default function getCorrectDate(fecha){
    const date = new Date(fecha);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let minutes = date.getMinutes();
    let hours = date.getUTCHours();

    if( month < 10 ){
        month = `0${month}`;
    }
    if( day < 10 ){
        day = `0${day}`;
    }
    if( minutes < 10 ){
        minutes = `0${minutes}`;
    }
    if( hours < 10 ){
        hours = `0${hours}`;
    }
    return `${day}/${month} - ${hours}:${minutes}`;
}