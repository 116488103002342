//Imports
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import Login from "../components/Login";

function HomePage(){

    return(
        <>
            <AuthenticatedTemplate>
                <div className='homePadre'>
                    <Link to="/courses/home">Courses</Link>
                    <Link to="/users/home">Users</Link>
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login/>
            </UnauthenticatedTemplate>
        </>
    )
}
export default HomePage;