//Imports
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ModalProvider } from "./context/ModalContext";

//Components
import HomePage from "./pages/HomePage";
import Courses from "./components/Courses";
import Users from "./components/Users";
import ErrorPage from "./components/ErrorPage";

import './styles/App.scss'
import { InfoUserProvider } from "./context/InfoUser";

function App() {
	return (
        <InfoUserProvider>
            <ModalProvider>
                <BrowserRouter>
                    <Routes>
                        <Route>
                            <Route path="/" exact={true} element={ <HomePage/> } />
                            <Route path="/courses/:uni" exact={true} element={ <Courses/> } />
                            <Route path="/users/:uni" exact={true} element={ <Users/> } />
                            <Route path="*" exact={true} element={ <ErrorPage/> } />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </ModalProvider>
        </InfoUserProvider>
	);
}

export default App;
