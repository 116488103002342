//Imports
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../middleware/msal-config";

function Login(){
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.log(e.message);
        });
    };

    return(
        <div className='loginPadre'>
            <button onClick={handleLogin}>Login</button>
        </div>
    )
}
export default Login;