//Imports
import { useContext } from "react";
import { ModalContext } from "../context/ModalContext";

//Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function ModalButton({ props, type }){

    const { setOpen, setContent, setType } = useContext(ModalContext);
    
    const openModal = () => {
        setContent({ title: props.title, body: props.body });
        setType(type);
        setOpen(true);
    }

    return(
        <div className='modalButton' onClick={() => openModal()}>
            <p>Add {type}</p>
            <FontAwesomeIcon icon={faPlus} />
        </div>
    )
}
export default ModalButton;