//Imports
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../middleware/msal-config";

function LogoutButton(){
    
    const { instance } = useMsal();
    const logoutSession = () => {
        instance.logoutRedirect(loginRequest).catch(e => {
            console.log(e.message)
        })
    }
    
    return(
        <button onClick={() => logoutSession()} className='logoutButton'>Logout</button>
    )
}
export default LogoutButton;